import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { BankAccount } from '@ts/finance/bank-account/shared/util-core';

@Component({
  selector: 'ts-finance-bank-account-select-modal',
  templateUrl: './finance-bank-account-select-modal.component.html',
  styleUrls: ['./finance-bank-account-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceBankAccountSelectModalComponent {
  @Input() initial?: BankAccount;

  constructor(private modalController: ModalController) {}

  bankAccountSelected(bankAccount: BankAccount | null) {
    this.modalController.dismiss({ bankAccount });
  }
}
