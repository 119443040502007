import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { InventoryRecordMetaPagingService } from '@ts/inventory/record/meta/list/data-access';
import { InventoryRecordMetaWrapper } from '@ts/inventory/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-inventory-record-meta-list-infinite',
  templateUrl: './inventory-record-meta-list-infinite.component.html',
  styleUrls: ['./inventory-record-meta-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryRecordMetaListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<
    ActionTemplateContext<InventoryRecordMetaWrapper>
  >;

  constructor(
    public inventoryRecordMetaPagingService: InventoryRecordMetaPagingService,
  ) {}
}
