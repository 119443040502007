import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FinanceBankAccountSelectModalComponent } from '@ts/finance/bank-account/action/feature-select';
import { BankAccount } from '@ts/finance/bank-account/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-finance-bank-account-form-field',
  templateUrl: './finance-bank-account-form-field.component.html',
  styleUrls: ['./finance-bank-account-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceBankAccountFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'bankAccount';
  initial?: BankAccount;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<BankAccount>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: FinanceBankAccountSelectModalComponent,
        componentProps: { initial: entity },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
