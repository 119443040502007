<tr *ngFor="let priceSellChange of priceSellChanges">
  <td>
    <div>{{ priceSellChange.title }}</div>
    <ts-guid-ui [guid]="priceSellChange.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="priceSellChange"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <ts-facility-mention-ui
      [facility]="priceSellChange.facility"
    ></ts-facility-mention-ui>
  </td>
  <td>
    {{ priceSellChange.status }}
  </td>
  <td>
    <ts-timestamp-ui
      [timestamp]="priceSellChange.date_start_timestamp"
    ></ts-timestamp-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: priceSellChange }"
    >
    </ng-template>
  </td>
</tr>
