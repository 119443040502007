import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { BankAccount } from '@ts/finance/bank-account/shared/util-core';
import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import {
  ApiEntityPagingAbstractService,
  EntityPagingService,
} from '@ts/shared/pagination/data-access-entity';

@Injectable({
  providedIn: 'root',
})
export class BankAccountPagingService extends ApiEntityPagingAbstractService<BankAccount> {
  relativeUrl$ = of('api/finance/bank-account/staff/search/');

  constructor(
    protected override apiService: BormaDagoAuthenticatedApiConsumerService,
    protected override entityPagingService: EntityPagingService,
  ) {
    super();
  }
}
