<ts-pagination-infinite-table [pagingService]="printerPagingService">
  <ts-printer-table-header-ui slot="header"></ts-printer-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-printer-table-body-ui
      [printers]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-printer-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
