<tr *ngFor="let printer of printers">
  <td>
    <div>{{ printer.name }}</div>
    <ts-guid-ui [guid]="printer.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="printer"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <ts-facility-mention-ui
      [facility]="printer.facility"
    ></ts-facility-mention-ui>
  </td>
  <td>
    {{ printer.type }}
  </td>
  <td>
    <ts-printer-connection-mention-ui
      [printer]="printer"
    ></ts-printer-connection-mention-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: printer }"
    >
    </ng-template>
  </td>
</tr>
