import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Printer } from '@ts/printer/shared/util-core';

@Component({
  selector: 'ts-printer-mention-ui',
  templateUrl: './printer-mention-ui.component.html',
  styleUrls: ['./printer-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterMentionUiComponent {
  @Input() printer!: Printer;
}
