<tr *ngFor="let inventoryRecordMetaWrapper of inventoryRecordMetaWrappers">
  <td>
    <div>
      <!--TODO(ivan): make inventory record mention ui for now placeholder -->
      <!-- {{
        inventoryRecordMetaWrapper.inventoryrecordmeta.inventory_record.product
          .title
      }} -->
    </div>
    <ts-date-created-updated-ui
      [instance]="inventoryRecordMetaWrapper"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <div>
      low:
      {{
        inventoryRecordMetaWrapper.inventoryrecordmeta.quantity_threshold_low
      }}
    </div>
    <div>
      high:
      {{
        inventoryRecordMetaWrapper.inventoryrecordmeta.quantity_threshold_high
      }}
    </div>
  </td>
  <td>
    <div>
      Multiplier:
      {{
        inventoryRecordMetaWrapper.inventoryrecordmeta
          .inventory_replenishment_quantity_multiplier
      }}
    </div>
    <div>
      Unit of Measure:
      {{
        inventoryRecordMetaWrapper.inventoryrecordmeta
          .inventory_replenishment_quantity_multiplier_unit_of_measure
      }}
    </div>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: inventoryRecordMetaWrapper }"
    >
    </ng-template>
  </td>
</tr>
