import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { BankAccount } from '@ts/finance/bank-account/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-finance-bank-account-table-body-ui',
  templateUrl: './finance-bank-account-table-body-ui.component.html',
  styleUrls: ['./finance-bank-account-table-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceBankAccountTableBodyUiComponent {
  @Input() bankAccounts!: readonly BankAccount[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<BankAccount>>;
}
