import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { BankAccountPagingService } from '@ts/finance/bank-account/list/data-access';
import { BankAccount } from '@ts/finance/bank-account/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-finance-bank-account-list-infinite',
  templateUrl: './finance-bank-account-list-infinite.component.html',
  styleUrls: ['./finance-bank-account-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceBankAccountListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<BankAccount>>;

  constructor(public bankAccountPagingService: BankAccountPagingService) {}
}
