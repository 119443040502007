import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FinanceBankAccountMentionUiComponent } from './finance-bank-account-mention-ui/finance-bank-account-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [FinanceBankAccountMentionUiComponent],
  exports: [FinanceBankAccountMentionUiComponent],
})
export class FinanceBankAccountUiMentionModule {}
