import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Printer } from '@ts/printer/shared/util-core';

@Component({
  selector: 'ts-printer-select-modal',
  templateUrl: './printer-select-modal.component.html',
  styleUrls: ['./printer-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterSelectModalComponent {
  @Input() initial?: Printer;

  constructor(private modalController: ModalController) {}

  printerSelected(printer: Printer | null) {
    this.modalController.dismiss({ printer });
  }
}
