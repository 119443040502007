import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BankAccount } from '@ts/finance/bank-account/shared/util-core';

@Component({
  selector: 'ts-finance-bank-account-mention-ui',
  templateUrl: './finance-bank-account-mention-ui.component.html',
  styleUrls: ['./finance-bank-account-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceBankAccountMentionUiComponent {
  @Input() bankAccount!: BankAccount;
}
