import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { PricePurchaseChangePagingService } from '@ts/price/purchase/change/list/data-access';
import { PricePurchaseChange } from '@ts/price/purchase/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-price-purchase-change-list-infinite',
  templateUrl: './price-purchase-change-list-infinite.component.html',
  styleUrls: ['./price-purchase-change-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePurchaseChangeListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<
    ActionTemplateContext<PricePurchaseChange>
  >;

  constructor(
    public pricePurchaseChangePagingService: PricePurchaseChangePagingService,
  ) {}
}
