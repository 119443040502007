import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Printer } from '@ts/printer/shared/util-core';

@Component({
  selector: 'ts-printer-connection-mention-ui',
  templateUrl: './printer-connection-mention-ui.component.html',
  styleUrls: ['./printer-connection-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterConnectionMentionUiComponent {
  @Input() printer!: Printer;
}
