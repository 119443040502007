import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { PrinterPagingService } from '@ts/printer/list/data-access';
import { Printer } from '@ts/printer/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-printer-list-infinite',
  templateUrl: './printer-list-infinite.component.html',
  styleUrls: ['./printer-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<Printer>>;

  constructor(public printerPagingService: PrinterPagingService) {}
}
