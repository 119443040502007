import { strict as assert } from 'assert';
import { filter, Observable, Subscription } from 'rxjs';

import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';

export const populateFromExtension: FormlyExtension = {
  prePopulate(field: FormlyFieldConfig) {
    const props = field.props || {};
    if (!props['populateFrom']) {
      return;
    }

    const { referenceKey, options } = props['populateFrom'];

    if (!referenceKey) {
      return;
    }

    const expressions = field.expressions;
    field.expressions = {
      ...(expressions || {}),
      populateFrom: (field) => {
        assert(field.parent);
        if (!field.parent.model) {
          return null;
        }
        return field.parent.model[referenceKey];
      },
    };

    //Todo(bambang): make form control parser
    //Only works for field with same parent for now

    let subscription: Subscription | undefined;
    const hooks = field.hooks;
    field.hooks = {
      ...(hooks || {}),
      onInit: (field) => {
        let hooksOnInitReturnValue: void | Observable<unknown> = undefined;
        if (hooks?.onInit) {
          hooksOnInitReturnValue = hooks.onInit(field);
        }

        assert(field.options);
        assert(field.options.fieldChanges);
        subscription = field.options.fieldChanges
          .pipe(
            filter((e) => {
              return (
                e.type === 'expressionChanges' &&
                e.field === field &&
                e['property'] === 'populateFrom'
              );
            }),
          )
          .subscribe((e) => {
            let value = e.value;
            if (value && options) {
              value = options.mapping(value);
            }

            // Need to wait for hidden changeHideState from formly to register field control
            // Doesn't need setTimeout if hide expression not used

            setTimeout(() => {
              assert(field.formControl);
              field.formControl.patchValue(value);
            });
          });

        return hooksOnInitReturnValue;
      },

      onDestroy: (field) => {
        if (hooks?.onDestroy) {
          hooks.onDestroy(field);
        }

        if (subscription) {
          subscription.unsubscribe();
        }
      },
    };
  },
};
