<div>
  Nama Lokal:
  <ng-container *ngIf="printer.printer_name_local">
    {{ printer.printer_name_local }}
  </ng-container>
  <ng-container *ngIf="!printer.printer_name_local"> - </ng-container>
</div>
<div>
  IP Port:
  <ng-container *ngIf="printer.printer_ip_port">
    {{ printer.printer_ip_port }}
  </ng-container>
  <ng-container *ngIf="!printer.printer_ip_port"> - </ng-container>
</div>
