<tr *ngFor="let bankAccount of bankAccounts">
  <td>
    <div>
      {{ bankAccount.bank_name }}
    </div>
    <div>Kota: {{ bankAccount.bank_city }}</div>
    <ts-date-created-updated-ui
      [instance]="bankAccount"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <div>No: {{ bankAccount.account_number }}</div>
    <div>A.N.: {{ bankAccount.account_holder_name }}</div>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: bankAccount }"
    ></ng-template>
  </td>
</tr>
