<tr *ngFor="let order of orders">
  <td>
    <div>
      <ts-link-ui
        [routerLinkParams]="['/order/detail', order.number]"
        routerDirection="forward"
      >
        {{ order.number }}
      </ts-link-ui>
    </div>
    <ts-date-created-updated-ui [instance]="order"></ts-date-created-updated-ui>
  </td>
  <td>
    <ts-order-status-ui [orderStatus]="order.status"></ts-order-status-ui>
  </td>
  <td>
    <ts-money-ui [money]="order.total_price"></ts-money-ui>
  </td>
  <td>
    <ts-user-mention-ui [user]="order.user"></ts-user-mention-ui>
  </td>
  <td>
    <ng-container *ngIf="order.shipping_info.address">
      {{ order.shipping_info.address.address }}
    </ng-container>
    <ng-container *ngIf="!order.shipping_info.address">
      Pesanan Offline
    </ng-container>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: order }"
    >
    </ng-template>
  </td>
</tr>
